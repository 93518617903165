import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField,Select,FormControl} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';

import classNames from 'classnames';
import ReactTable from './shares/react_table';
import theme,{styles} from '../layout/theme';
import ReactLazyCardMedia from './shares/react_lazy_card';
import Utils from '../api/api';

export class NewsList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false,
      filter: '',
      status: '',
      category: '',
      categories: [],
      statuses: ['Draft','Publish'],
      dateStart: '',
      dateEnd: '',
      selected:[]
    }
  }
  componentDidMount() {
    const $this = this;
    this.setState({
      sorts: JSON.stringify({date:1})
    });
    Utils.getListData('newscats','all',100,'0,0',null,1,function(data){
      if (data.status === 'success') {
        $this.setState({categories: data.results});
      }
    });
  }
  handleSearch(e){
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd(){
    this.context.router.history.push('/news/add');
  }
  filterData(e){
    const $this = this,
          state = $this.state;

    state[e.target.id] = e.target.value.trim();
    $this.setState(state,function(){
      $this.handleFilter();
    });
  }
  handleFilter() {
    let f = this.state.filter;

    if(f==='') {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if(this.state.category!==''){
      f.category = parseInt(this.state.category);
    } else {
      if(f.category!==undefined) {
        delete f.category;
      }
    }

    if(this.state.status!==''){
      f.status = parseInt(this.state.status);
    } else {
      if(f.status!==undefined) {
        delete f.status;
      }
    }

    if(this.state.dateStart!==''){
      f.date = {$gte:new Date(this.state.dateStart + ' 23:59:59')};
    } else {
      if(f.date!==undefined && this.state.dateEnd==='') {
        delete f.date;
      }
    }

    if(this.state.dateEnd!==''){
      f.date = {$lte:new Date(this.state.dateEnd + ' 23:59:59')};
    } else {
      if(f.date!==undefined && this.state.dateStart==='') {
        delete f.date;
      }
    }

    this.setState({filter:JSON.stringify(f)});
  }
  onChangeSelect(data){
    this.setState({selected:data});
  }
  handleActive(data) {
    const $this = this;
    var arr = [];
    data.map(x=>{
      let o = {_id:x._id,status:1};
      arr.push(o);
    });
    Utils._updateMany('news',arr,function(data){
      if(data.status==='success') {
        $this.handleFilter();
      }
    });
  }
  handleInActive(data) {
    const $this = this;
    var arr = [];
    data.map(x=>{
      let o = {_id:x._id,status:0};
      arr.push(o);
    });
    Utils._updateMany('news',arr,function(data){
      if(data.status==='success') {
        $this.handleFilter();
      }
    });
  }
  render(){
    const $this = this;
    const classes = $this.props.classes;
    var source = {
      thead: [{title:'Tiêu đề',value:'title'}, { title: 'SEO URL', value: 'url'},{title:'Hình',value:'photo'},{title:'Trạng thái',value:'status'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          title: n.titleVN,
          url: <div><p>{n.urlVN}</p><p>{n.urlEN}</p></div>,
          photo: <div className={classes.thumbnailWrapper}><ReactLazyCardMedia className={classes.thumbnail} image={n.thumb !== '' && n.thumb !== undefined ? Utils.fixImagePath(n.thumb) : Utils.fixImagePath('/data/upload/images/no_photo.png',{width:100,height:60})} title={n.titleVN} /></div>,
          status: n.status!=1?'Draft':'Publish'
        })
      }
    };

    const Bulk = [{
      title: 'Đăng bài (Publish)',
      action: (data)=>$this.handleActive(data)},{
      title: 'Gỡ bài (To Draft)',
      action: (data)=>$this.handleInActive(data)}];

    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục tin tức</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
          <FormGroup className={classes.formRowToolbar}>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col3}>
                  <Typography className={classes.label}>Nhóm</Typography>
                  <Select
                    native
                    id="category"
                    value={$this.state.category}
                    onChange={event => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.categories.map((item, index) => {
                      return (
                        <option key={index + 1} value={item._id}>
                          {item.nameVN}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col3}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    id="status"
                    value={$this.state.status}
                    onChange={event => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.statuses.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col3}>
                  <TextField type="date" id="dateStart" label="Từ" className={classNames(classes.textField + ' form-control-no-bottom')} value={Utils.formatDate(this.state.dateStart)} onChange={event => this.filterData(event)}
                  margin="normal"/>
                </FormControl>
                <FormControl className={classes.col3}>
                  <TextField type="date" id="dateEnd" label="Đến" className={classNames(classes.textField + ' form-control-no-bottom')} value={Utils.formatDate(this.state.dateEnd)} onChange={event => this.filterData(event)}
                  margin="normal"/>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col1}>
                  <TextField type="search" id="keyword" label="Tìm kiếm" className={classNames(classes.textField + ' form-control-no-bottom')} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
                  margin="normal"/>
                </FormControl>
              </FormGroup>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiBulk={Bulk} onChangeSelect={(items)=>$this.onChangeSelect(items)} showCheckBox={true} apiDuplicate={true} apiList='newses' apiSingle='news' apiTable='news' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} apiFilter={this.state.filter}>
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

NewsList.propTypes = {
  classes: PropTypes.object.isRequired,
};
NewsList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(NewsList);
