import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import Table, {TableBody, TableCell, TableHead, TableRow} from '@material-ui/core/Table';
// import Paper from '@material-ui/core/Paper';
import {
  TextField,
  Typography,
  Button,
  FormLabel,
  Toolbar,
  Chip
} from "@material-ui/core";
import { Tabs, Tab } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { FormGroup, FormControl } from "@material-ui/core";
import TabContainer from "./shares/react_tab_container";
import language from "../layout/language";

import Select from "@material-ui/core/Select";
import ImageControl from "./shares/react_image_control";


// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import "froala-editor/js/third_party/ionicons.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
import "froala-editor/css/third_party/font_awesome.min.css";
import "froala-editor/css/third_party/ionicons.min.css";

import FroalaEditor from "react-froala-wysiwyg";

import classNames from "classnames";
import Utils from "../api/api";
import theme, { styles } from "../layout/theme";

// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }

class NewsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      tags: [],
      newsrel: [],
      status: ['Draft','Publish'],
      news: {
        titleVN: "",
        subtitleVN: "",
        author: "",
        date: new Date().toString(),
        type: "News",
        urlVN: "",
        urlEN: "",
        thumb: "",
        summaryVN: "",
        detailVN: "",
        status: 0,
        titleEN: "",
        subtitleEN: "",
        summaryEN: "",
        detailEN: "",
        descriptionVN: "",
        descriptionEN: "",
        category: "",
        tags: [],
        relates: []
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        titleVN_error: false,
        summaryVN_error: false,
        detailVN_error: false,
        titleEN_error: false,
        summaryEN_error: false,
        detailEN_error: false
        // titleKR_error: false,
        // summaryKR_error: false,
        // detailKR_error: false,
      },
      failed: false,
      message: "",
      isUpdate: false,
      tab: 0,
      open: false,
      options: {'empty':'empty'}
    };
  }
  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData("newscats", 1, 100, "0,0", null, 1, function(data) {
      //console.log(data);
      if (data.status == "success") {
        $this.setState({ categories: data.results });
      }
    });

    Utils.getListData("tags", 1, 1000, "0,0", null, 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ tags: data.results });
      }
    });

    Utils.getBlockContents(function(options){
      $this.setState({options:options});
    });

    if (params.id !== undefined) {
      $this.setState(
        {
          isUpdate: true
        },
        function() {
          Utils.getSingleData("news", params.id, function(res) {
            if (res.status === "success" && res.results !== undefined) {
              var data = {},
                item = res.results;
              for (var property in item) {
                if (item.hasOwnProperty(property)) {
                  data[property] = item[property];
                }
              }
              data._id = item._id;
              if (data.urlVN === undefined) data.urlVN = '';
              if (data.urlEN === undefined) data.urlEN = '';
              if (data.tags === undefined) data.tags = [];
              if (data.category === undefined || data.category === null)
                data.category = "";
              $this.setState({ news: data });
            }
          });
        }
      );
    }
  }
  onEditorStateChange(field, value) {
    var news = this.state.news,
      error = this.state.error;
    news[field] = value;
    error[field + "_error"] = value === "";

    if (field.indexOf("summary") !== -1) {
      var locale = field.replace("summary", "");
      var temporalDivElement = document.createElement("div");
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = value;
      // Retrieve the text property of the element (cross-browser support)
      var text =
        temporalDivElement.textContent || temporalDivElement.innerText || "";
      news["description" + locale] = text.substring(0, 200);
    }

    this.setState({ news: news, error: error });
  }
  handleImageChange(value) {
    var news = this.state.news;
    news.thumb = value;
    this.setState({ news: news });
  }
  handleFieldChange(e) {
    var news = this.state.news;
    news[e.target.id] = e.target.value;
    if (e.target.type == "textarea") {
      news[e.target.id] = e.target.value.substring(0, 200);
    }
    this.setState({ news: news });
  }
  handleValidate(e, flag) {
    var $this = this,
      news = this.state.news,
      obj = this.state.error;

    news[e.target.id] = e.target.value;

    var p = e.target.id + "_error";

    if (e.target.value.trim() === "" || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    if (e.target.id === "titleVN") {
      var urlVN = Utils.getFriendlyURL(e.target.value.trim());
      news.urlVN = urlVN;
    }
    if (e.target.id === "titleEN") {
      var urlEN = Utils.getFriendlyURL(e.target.value.trim());
      news.urlEN = urlEN;
    }

    this.setState({ news: news, error: obj }, () => {
      if (
        !$this.state.error.titleVN_error &&
        !$this.state.error.summaryVN_error &&
        !$this.state.error.detailVN_error
      ) {
        obj["vn"] = false;
      } else {
        obj["vn"] = true;
      }
      if (
        !$this.state.error.titleEN_error &&
        !$this.state.error.summaryEN_error &&
        !$this.state.error.detailEN_error
      ) {
        obj["en"] = false;
      } else {
        obj["en"] = true;
      }
      // if(!$this.state.error.titleKR_error && !$this.state.error.summaryKR_error && !$this.state.error.detailKR_error){
      //   obj['kr'] = false;
      // }else{
      //   obj['kr'] = true;
      // }
      $this.setState({ error: obj });
    });
  }
  _save() {
    var $this = this;
    var data = this.state.news;

    data.status = parseInt(data.status);

    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update("news", data, function(res) {
        //console.log(res);
        if (res.status === "success") {
          $this.context.router.history.push("/news/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    } else {
      Utils._add("news", data, function(res) {
        if (res.status == "success") {
          $this.context.router.history.push("/news/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    }
  }
  handleSave(e, flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({ isUpdate: true });
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch (flag) {
        case "vn":
          obj["titleVN_error"] = this.state.news.titleVN === "";
          obj["summaryVN_error"] = this.state.news.summaryVN === "";
          obj["detailVN_error"] = this.state.news.detailVN === "";
          obj["vn"] =
            obj["titleVN_error"] ||
            obj["summaryVN_error"] ||
            obj["detailVN_error"];
          this.setState({ error: obj }, function() {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        case "en":
          obj["titleEN_error"] = this.state.news.titleEN === "";
          obj["summaryEN_error"] = this.state.news.summaryEN === "";
          obj["detailEN_error"] = this.state.news.detailEN === "";
          obj["en"] =
            obj["titleEN_error"] ||
            obj["summaryEN_error"] ||
            obj["detailEN_error"];
          this.setState({ error: obj }, function() {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['titleKR_error'] = this.state.news.titleKR === '';
        //   obj['summaryKR_error'] = this.state.news.summaryKR === '';
        //   obj['detailKR_error'] = this.state.news.detailKR === '';
        //   obj['kr'] = obj['titleKR_error'] || obj['summaryKR_error'] || obj['detailKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push("/news/");
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab: value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  selectImg(value) {
    var $this = this,
      obj = this.state.news;
    obj.thumb = value;
    this.setState({ news: obj }, function() {
      $this.handleClose();
    });
  }
  handleChange(e) {
    var $this = this,
      obj = this.state.news;
    obj.type = e.target.value;
    this.setState({ news: obj });
  }
  handleTags(e) {
    var code = e.keyCode || e.which,
      news = this.state.news,
      tags = this.state.tags;
    if (code === 13) {
      var a_tags = e.target.value.split(","),
        trim_arr = [];
      for (var tag in a_tags) {
        let obj = { tag: a_tags[tag].trim(), appear: 1 };
        if(a_tags[tag].trim()!=='')trim_arr.push(a_tags[tag].trim());
        Utils._add("tag", obj, function(res) {});
      }
      var res = Utils.arrayUnique(news.tags.concat(trim_arr));
      news.tags = res;
      this.setState({ news: news, tags: res });
    }
  }
  choiceTags(e) {
    var news = this.state.news;
    news.tags.push(e.target.value);
    this.setState({ news: news }, function() {});
  }
  handleDeleteTag(tag, index) {
    var news = this.state.news,
      tags = news.tags,
      all_tags = this.state.tags;
    tags.splice(index, index + 1);
    news.tags = tags;
    this.setState({ news: news }, function() {
      var _tag = all_tags.filter(x => {
        return x.tag === tag;
      });
      if (_tag.length) {
        _tag.appear = _tag.appear - 1;
        Utils._update("tag", _tag);
      }
    });
  }
  handleCopy() {
    var data = this.state.news;
    data.titleEN = data.titleVN;
    data.subtitleEN = data.subtitleVN;
    data.summaryEN = data.summaryVN;
    data.detailEN = data.detailVN;
    data.descriptionEN = data.descriptionVN;
    this.setState({
      news: data
    });
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const news = this.state.news;
    const editorConfigBasic = Utils.setupBasicEditor();
    const editorConfig = Utils.setupEditor('news',this.state.options);
    var tags = [];
    for (var i = 0, len = this.state.tags.length; i < len; i++) {
      var item = this.state.tags[i];
      var disabled = false;
      for (var y = 0, leny = news.tags.length; y < leny; y++) {
        disabled = item.tag === news.tags[y].trim();
        if (disabled) break;
      }
      tags.push(
        <option
          disabled={disabled}
          key={i + 1}
          data-id={item._id}
          data-appear={item.appear}
          value={item.tag}
        >
          {item.tag}
        </option>
      );
    }
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Thêm/Sửa Tin tức
          </Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.vn && classes.hide
                )}
              >
                {language.VN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                aria-label="copy"
                onClick={() => this.handleCopy()}
              >
                Copy to [EN]
              </Button>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col6}>
                  <Select
                    native
                    value={this.state.news.type}
                    onChange={event => this.handleChange(event)}
                  >
                    <option value="News">News</option>
                    <option value="Event">Event</option>
                  </Select>
                </FormControl>
                <FormControl className={classes.col6}>
                  <Typography className={classes.label} type="caption">
                    Nhóm tin tức
                  </Typography>
                  <Select
                    native
                    id="category"
                    value={news.category}
                    onChange={event => this.handleFieldChange(event)}
                  >
                    <option key={0} value="">
                      ...
                    </option>
                    {this.state.categories.map((item, index) => (
                      <option key={index + 1} value={item._id}>
                        {item.nameVN}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
              <TextField
                id="titleVN"
                label={language.VN.title}
                className={classes.textField}
                value={news.titleVN}
                onChange={event => this.handleValidate(event, "vn")}
                onBlur={event => this.handleValidate(event, "vn")}
                required={true}
                error={this.state.error.titleVN_error}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="urlVN"
                label="Link [SEO]"
                className={classes.textField}
                value={news.urlVN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="subtitleVN"
                label={language.VN.subTitle}
                className={classes.textField}
                value={news.subtitleVN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col4}>
                  <TextField
                    id="author"
                    label={language.VN.author}
                    className="form-control-no-bottom"
                    value={news.author}
                    onChange={event => this.handleFieldChange(event)}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
                <FormControl className={classes.col4}>
                  <TextField
                    id="date"
                    type="date"
                    label={language.VN.date}
                    className="form-control-no-bottom"
                    value={Utils.formatDate(news.date)}
                    onChange={event => this.handleFieldChange(event)}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
                <FormControl className={classes.col4}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    className={classes.selectMultiLevel}
                    id="status"
                    value={news.status}
                    onChange={event => this.handleFieldChange(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.status.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRow}>
                <ImageControl
                  value={news.thumb}
                  handleChange={value => this.handleImageChange(value)}
                />
              </FormGroup>
              <FormGroup className={classes.formRow}>
                <FormControl>
                  <Typography className={classes.label} type="caption">
                    Tags (Mỗi tag cách nhau dấu phẩy)
                  </Typography>
                  <TextField
                    id="tags"
                    className={classes.textField}
                    onKeyPress={event => this.handleTags(event)}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <Select
                    native
                    className={"select " + classes.select}
                    onChange={event => this.handleTags(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {tags}
                  </Select>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.chipRow}>
                {news.tags !== undefined &&
                  news.tags !== null &&
                  news.tags.map((item, index) => (
                    <Chip
                      label={item}
                      key={index}
                      onDelete={() => this.handleDeleteTag(item, index)}
                      className={classes.chip}
                    />
                  ))}
              </FormGroup>
              <TextField
                id="descriptionVN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                className={classes.textField}
                value={news.descriptionVN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.summaryVN_error}
              >
                {language.VN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={news.summaryVN}
                onModelChange={value =>
                  this.onEditorStateChange("summaryVN", value)
                }
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailVN_error}
              >
                {language.VN.detail}
              </FormLabel>
              {
                this.state.options['empty']!=='empty' && (
                  <FroalaEditor
                    tag="textarea"
                    config={editorConfig}
                    model={news.detailVN}
                    onModelChange={value =>
                      this.onEditorStateChange("detailVN", value)
                    }
                  />
                )
              }
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={event => this.handleCancel(event)}
                >
                  {language.VN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "vn")}
                >
                  {language.VN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.en && classes.hide
                )}
              >
                {language.EN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <TextField
                id="titleEN"
                label={language.EN.title}
                className={classes.textField}
                value={this.state.news.titleEN}
                onChange={event => this.handleValidate(event, "en")}
                onBlur={event => this.handleValidate(event, "en")}
                required={true}
                error={this.state.error.titleEN_error}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="urlEN"
                label="Link [SEO]"
                className={classes.textField}
                value={news.urlEN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="subtitleEN"
                label={language.EN.subTitle}
                className={classes.textField}
                value={this.state.news.subtitleEN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id="descriptionEN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                className={classes.textField}
                value={news.descriptionEN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.summaryEN_error}
              >
                {language.EN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={news.summaryEN}
                onModelChange={value =>
                  this.onEditorStateChange("summaryEN", value)
                }
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailEN_error}
              >
                {language.EN.detail}
              </FormLabel>
              {
                this.state.options['empty']!=='empty' && (
                  <FroalaEditor
                    tag="textarea"
                    config={editorConfig}
                    model={news.detailEN}
                    onModelChange={value =>
                      this.onEditorStateChange("detailEN", value)
                    }
                  />
                )
              }
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={event => this.handleCancel(event)}
                >
                  {language.EN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "en")}
                >
                  {language.EN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleKR" label={language.KR.title} className={classes.textField} value={news.titleKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.titleKR_error} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="subtitleKR" label={language.KR.subTitle} className={classes.textField} value={news.subtitleKR} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <TextField id="descriptionKR" rows="5" multiline={true} label="Meta Description (max. length: 200 characters)" className={classes.textField} value={news.descriptionKR} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.summaryKR_error}>{language.KR.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={this.state.news.summaryKR}
              onModelChange={(value)=>this.onEditorStateChange('summaryKR',value)}
            />
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailKR_error}>{language.KR.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={this.state.news.detailKR}
              onModelChange={(value)=>this.onEditorStateChange('detailKR',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
        {/* <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <div className={classes.dialogMedia}>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={this.handleClose}>
                {language.VN.btnCancel}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSelect}
              >
                {language.VN.btnSave}
              </Button>
            </FormGroup>
            <Media onSelectChange={value => this.selectImg(value)} />
          </div>
        </Dialog> */}
      </div>
    );
  }
}

NewsDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
NewsDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(NewsDetail);
